import React from 'react';
import Button from '@ui/components/ui/button/Button';
import {
  type AirportGoogleMapData,
  GoogleMaps,
} from '../../../google-maps/google-maps';
import Image from 'next/image';
import { type AirportLocationsFieldsFragment } from '../../../../graphql/generated/graphql';
import { GroupAirportsByCountry } from '../../../../helpers/group-airport-by-country/group-airport-by-country';

type MapViewProps = {
  airportData: Array<AirportLocationsFieldsFragment>;
};

// Function to calculate distance between two points
const calculateDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
): number => {
  const toRad = (value: number) => (value * Math.PI) / 180;
  const R = 6371; // Earth's radius in km
  const dLat = toRad(lat2 - lat1);
  const dLng = toRad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  return R * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
};

// Helper function to convert AirportLocationsFieldsFragment to AirportData
const convertToAirportData = (
  airport: AirportLocationsFieldsFragment
): AirportGoogleMapData => {
  const name = airport.airport?.name ?? 'Unnamed Airport';
  const lat = airport.airport?.location?.lat ?? 0;
  const lng = airport.airport?.location?.long ?? 0;

  return { name, lat, lng };
};

export const MapView: React.FC<MapViewProps> = ({ airportData }) => {
  const [selectedCountry, setSelectedCountry] =
    React.useState<string>('Netherlands');
  const [selectedAirport, setSelectedAirport] = React.useState<
    AirportGoogleMapData | undefined
  >();
  const [error, setError] = React.useState<string | null>();

  const filteredAirports = selectedCountry
    ? airportData
        .filter(
          airport => airport.airport?.location?.country?.name === selectedCountry
        )
        .map(element => convertToAirportData(element)) // Convert each airport to AirportData
    : [];

  // User open the map, we will auto-detect the nearest country and set as default
  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        let nearestCountry = selectedCountry;
        let shortestDistance = Infinity;

        // Loop over the airport data
        for (const airport of airportData) {
          const airportLocation = airport.airport?.location;
          if (airportLocation && airportLocation.lat && airportLocation.long) {
            const distance = calculateDistance(
              userLocation.lat,
              userLocation.lng,
              airportLocation.lat,
              airportLocation.long
            );

            // Compare the distance and find the nearest airport
            if (distance < shortestDistance) {
              shortestDistance = distance;
              nearestCountry = airportLocation.country?.name || 'Unknown Country';
            }
          }
        }

        setSelectedCountry(nearestCountry);
      },
      error => {
        setError(`Error detecting location: ${error.message}`);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airportData]);

  const groupedAirports = GroupAirportsByCountry(airportData);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 pt-5 lg:pt-10">
      {error && (
        <div className="col-span-12 text-center bg-red-100 text-red-600 p-4 rounded-lg mb-4">
          {error}
        </div>
      )}
      {/* Country and Airport List Section */}
      <div className="col-span-12 lg:col-span-6 order-2 grid grid-cols-2 pt-4 lg:pt-0">
        {/* Country List */}
        <div className="bg-none">
          <ul className="space-y-7">
            {Object.keys(groupedAirports).map(countryName => (
              <li
                key={countryName}
                className="flex items-center text-lg xl:text-2xl font-normal"
              >
                <Button
                  variant="link"
                  intent="underline"
                  size="small"
                  isActive={selectedCountry === countryName}
                  onClick={() => setSelectedCountry(countryName)}
                >
                  <div className="flex items-center">
                    {groupedAirports[countryName]?.countryFlag &&
                      groupedAirports[countryName].countryFlag !== 'unknown' && (
                        <Image
                          src={groupedAirports[countryName].countryFlag}
                          alt={`${countryName} flag`}
                          width={24}
                          height={16}
                        />
                      )}{' '}
                    <span className="pl-4">{countryName}</span>
                  </div>
                </Button>
              </li>
            ))}
          </ul>
        </div>

        {/* Airports List */}
        <div className="bg-none">
          <ul className="space-y-3 lg:space-y-7">
            {filteredAirports.map((airport: AirportGoogleMapData) => (
              <li
                key={airport.name}
                className="font-light text-lg xl:text-2xl -ml-2"
              >
                <Button
                  variant="link"
                  size="small"
                  onClick={() => setSelectedAirport(airport)}
                  isActive={selectedAirport?.name === airport.name}
                  leftAlignText
                >
                  {airport.name}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Map Section */}
      <div className="col-span-12 lg:col-span-6 order-1 lg:order-2 relative">
        <div className="h-[350px] lg:h-[500px] rounded-3xl overflow-hidden shadow-md">
          <GoogleMaps
            selectedCountry={selectedCountry}
            selectedAirport={selectedAirport}
            filteredAirports={filteredAirports}
            onItemSelect={setSelectedAirport}
          />
        </div>
      </div>
    </div>
  );
};
