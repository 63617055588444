'use client';
import { Container } from '@ui/components/ui/container';
import { CarouselItem, CustomCarousel } from '@ui/components';
import { Icon } from '@icons/icons';
import { useTranslations } from 'next-intl';

export const HowDoesItWork = () => {
  const t = useTranslations('how_does_it_work');
  const steps = [
    {
      icon: <Icon name="CompareIcon" size={12} />,
      title: t('steps.compare.title'),
      description: t('steps.compare.description'),
    },
    {
      icon: <Icon name="BookIcon" size={12} />,
      title: t('steps.book.title'),
      description: t('steps.book.description'),
    },
    {
      icon: <Icon name="ParkIcon" size={12} />,
      title: t('steps.park.title'),
      description: t('steps.park.description'),
    },
    {
      icon: <Icon name="RelaxIcon" size={12} iconColor="light" />,
      title: t('steps.relax.title'),
      description: t('steps.relax.description'),
    },
  ];

  return (
    <div className="py-10 bg-white">
      <Container>
        <span className="text-xl lg:text-3xl font-normal">{t('title')}</span>

        <div className="lg:hidden">
          <div className="relative pb-10">
            <div className="mt-8">
              <CustomCarousel carouselNumber={steps.length}>
                {steps.map((step, index) => (
                  <CarouselItem key={index}>
                    <div className="text-center">
                      <div className="flex justify-center mb-4">{step.icon}</div>
                      <div className="text-2xl mt-4 font-medium">{step.title}</div>
                      <div className="mt-2 text-lg font-light">
                        {step.description}
                      </div>
                    </div>
                  </CarouselItem>
                ))}
              </CustomCarousel>
            </div>
          </div>
        </div>

        <div className="hidden lg:flex my-10 gap-16">
          {steps.map(step => (
            <div key={step.title} className="text-center">
              <div className="flex justify-center">{step.icon}</div>
              <div className="text-2xl font-medium">{step.title}</div>
              <div className="mt-4 text-lg font-light">{step.description}</div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};
