'use client';

import React, { useState } from 'react';
import { Container } from '@ui/components/ui/container';
import Image from 'next/image';
import Button from '@ui/components/ui/button/Button';
import { Card, Tabs, TabsList, TabsTrigger } from '@ui/components';
import AirportPlaceHolder from '/public/assets/AirportPlaceholder.png';
import { MapView } from './map-view/map-view';
import { useTranslations } from 'next-intl';
import { type AirportLocationsFieldsFragment } from '../../../graphql/generated/graphql';
import { GroupAirportsByCountry } from '../../../helpers/group-airport-by-country/group-airport-by-country';
import { Icon } from '@icons/icons';

export const AirportLocations = ({
  countries,
}: {
  countries: Array<AirportLocationsFieldsFragment>;
}) => {
  const [activeTab, setActiveTab] = useState('card');
  const [showAll, setShowAll] = useState<Record<string, boolean>>({});
  const t = useTranslations('airport_locations');
  const toggleShowAll = (country: string) => {
    setShowAll(prev => ({ ...prev, [country]: !prev[country] }));
  };

  const groupedByCountry = GroupAirportsByCountry(countries);

  return (
    <div className="py-10 bg-primaryBackground">
      <Container>
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <div className="flex justify-between items-center">
            <span className="text-xl lg:text-3xl font-normal">{t('title')}</span>
            <TabsList>
              <div className="flex gap-2">
                <TabsTrigger value="card" variant="default">
                  <Button
                    asDiv
                    size="large"
                    radius="fullRounded"
                    variant="secondary"
                    isActive={activeTab === 'card'}
                  >
                    <div className="flex items-center gap-1">
                      <Icon
                        name="CardIcon"
                        size={5}
                        iconColor="inherit"
                        className="align-middle"
                      />
                      <span className="hidden lg:inline text-base font-medium">
                        {t('card_view')}
                      </span>
                    </div>
                  </Button>
                </TabsTrigger>

                <TabsTrigger value="map" variant="default">
                  <Button
                    asDiv
                    size="large"
                    radius="fullRounded"
                    variant="secondary"
                    isActive={activeTab === 'map'}
                  >
                    <div className="flex items-center gap-1">
                      <Icon name="MapIcon" size={5} iconColor="inherit" />
                      <span className="hidden lg:inline text-base font-medium">
                        {t('map_view')}
                      </span>
                    </div>
                  </Button>
                </TabsTrigger>
              </div>
            </TabsList>
          </div>

          {activeTab === 'map' ? (
            <MapView airportData={countries} />
          ) : (
            Object.entries(groupedByCountry).map(([countryName, group]) => (
              <div key={countryName}>
                <div className="flex items-center justify-between pt-6">
                  <div className="flex items-center">
                    <span className="ml-2 text-lg lg:text-2xl font-normal flex items-center gap-2">
                      {group.airports[0]?.airport?.location?.country
                        ?.flagIconUrl && (
                        <Image
                          src={
                            group.airports[0].airport.location.country.flagIconUrl
                          }
                          alt={`${countryName} flag`}
                          width={30}
                          height={30}
                        />
                      )}
                      {countryName}
                    </span>
                  </div>
                  {group.airports.length > 4 && (
                    <div className="hidden lg:flex">
                      <Button onClick={() => toggleShowAll(countryName)}>
                        {showAll[countryName] ? t('show_less') : t('show_more')}
                      </Button>
                    </div>
                  )}
                </div>
                <div className="-mx-4 lg:-mx-8 flex overflow-x-auto gap-4 scroll-smooth no-scrollbar lg:grid lg:grid-cols-4 lg:gap-8 p-8">
                  {group.airports
                    .slice(0, showAll[countryName] ? undefined : 4)
                    .map(siteAirport => (
                      <Card key={siteAirport.id} variant="withPicture" shadow>
                        <div className="w-full flex items-center justify-center">
                          <Image
                            alt={siteAirport.airport?.name ?? 'Airport'}
                            src={AirportPlaceHolder}
                          />
                        </div>
                        <div className="p-3">
                          <p className="text-lg font-normal">
                            {siteAirport.airport?.name}
                          </p>
                          <p className="text-sm text-gray-500 mt-2">
                            {siteAirport.airport?.location?.country?.name}
                          </p>
                        </div>
                      </Card>
                    ))}
                </div>
              </div>
            ))
          )}
        </Tabs>
      </Container>
    </div>
  );
};
